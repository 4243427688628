.experince-section {
    background-size: cover;
    background-position: center;
    height: 105vh;
    width: 105%;
    display: flex;
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;
    overflow-y: scroll;
}

.entry-container {
    display: flex;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
}

.expirience-card {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.experience-content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
    padding: 5%;
}

.experience-info {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.data-title {
    font-size: 1.9rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.vertical-line {
    height: auto;
    width: 0.1rem;
    background: white;
    margin-right: 1rem;
}

.data-text {
    width: 30rem;
}

.data-tags {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.data-tag {
    border: solid;
    border-color: white;
    border-radius: 5rem;
    margin-right: 0.5rem;
    padding: 0.5rem;
}

.data-button {
    display: flex;
    width: 9rem;
    height: 2.5rem;
    border: 0rem;
    background: white;
    color: black;
    border-radius: 5rem;
    margin-right: 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 10px, 20px, 10px, 20px;
    margin-top: 5rem;
    font-weight: 600;
}

.date-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.date {
    font-size: 2.8rem;
    font-weight: 600;
}

.arrows {
    margin: 1.5rem;
}

@media only screen and (max-width: 768px) {

    .experince-section {
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100%;
        display: flex;
        color: white;
        text-align: center;
        position: relative;
        overflow: hidden;
        flex-direction: column;
    }

    .entry-container {
        display: flex;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        flex-wrap: nowrap;
    }

    .experience-content {
        width: 100vw;
        height: 100vh;
        padding: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .experience-info {
        text-align: center;
        align-items: center;
    }

    .data-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .vertical-line {
        display: none;
    }

    .data-text {
        width: 100%;
        font-size: 1rem;
    }

    .data-tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1rem;
        gap: 0.5rem;
    }

    .data-tag {
        margin: 0.5rem 0;
        padding: 0.5rem .5rem;
        flex: 0 1 auto;
    }

    .data-button {
        width: 60%;
        height: 3rem;
        margin-top: 2rem;
        font-size: 1rem;
    }

    .date-slider {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: 10%;
        padding: 0 2rem;
    }

    .date {
        font-size: 1.5rem;
        margin: 0 auto;
    }

    .arrows {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
    }

    .arrows.hidden {
        visibility: hidden;
    }
}