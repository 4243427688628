@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,600;0,700;1,300&display=swap');

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.title-text {
  font-size: 64px;
  font-weight: 600;
  line-height: 105%;
  background: var(--Degrade, linear-gradient(90deg, #B224EF 0%, #7579FF 100%));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .App {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
  }
}