.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: visible;
}

.lottie {
    margin-top: -10rem;
    margin-left: -13rem;
}



.intro {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: -7rem;
}


.greeding {
    display: flex;
    font-size: 6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
    color: var(--Negro-Lineal, #2a2c55);
    margin-left: -5rem;
}

.first-line {
    display: flex;
    margin-left: -15rem;
    margin-bottom: 1rem;

}

.highlight {
    position: relative;
    left: -1rem;
    top: -2rem;
}

.button-section {
    display: flex;
    align-items: center;
    margin-left: -5rem;
}

.button-main {
    display: inline-flex;
    padding: 16px 45px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: linear-gradient(90deg, #A65224 0%, #FE7624 68.5%);
    border: 0px;
}

.button-text {
    font-family: Poppins;
    font-size: 24px;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}




.description {
    width: 30rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    margin-top: 1.8rem;
    margin-left: -5rem;
    margin-bottom: -2rem;
    text-align: justify;
}


.curved-arrow {
    position: relative;
    top: 3rem;
    left: 2rem;
}


@media (max-width: 425px) {
    .container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        box-sizing: border-box;
        width: 100%;
        min-height: 100vh;
    }

    .lottie {
        height: auto;
        margin: 0;
        margin-left: -9rem;
    }

    .intro {
        width: 70%;
        text-align: left;
    }

    .greeding {
        font-size: 2rem;
        margin-left: 9rem;
        margin-top: -4rem;
        margin-bottom: 4rem;
        text-align: left;
        white-space: nowrap;
    }

    .first-line {
        display: flex;
        margin-left: -8rem;
        margin-bottom: 0.5rem;
        justify-content: flex-start;
        margin-top: -9rem;
        white-space: nowrap;

    }

    .highlight {
        position: relative;
        top: -5rem;
        width: 3rem;
        height: 2rem;
    }

    .description {
        width: 100%;
        font-size: .8rem;
        margin: 10rem 0 0 0;
        text-align: justify;
        padding: 0;
    }

    .button-section {
        justify-content: center;
        margin-left: 0;
        margin-top: 1.5rem;
    }

    .button-main {
        padding: 1rem 1rem;
        font-size: .5rem;
    }

    .button-text {
        font-family: Poppins;
        font-size: 1rem;
    }

    .curved-arrow {
        display: none;
    }
}