.skill-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    padding-top: 5rem;
}

.skill-title {
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
}

.skill-title-text {
    color: #2A2C55;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1rem;

}

.accent-title {
    height: 3rem;
    width: 10rem;
    position: relative;
    left: -3rem;
    top: -2rem;
}

.accent-title-reverse {
    height: 3rem;
    width: 10rem;
    position: relative;
    right: -3rem;
    top: -2rem;
    transform: rotateY(180deg);
}

.sub-title-skills {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sub-title-text {
    font-size: 1.5rem;
    font-weight: 600;
    background: linear-gradient(90deg, #A65224 0%, #FE7624 68.5%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skill-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 2rem;
}

.skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 20%;
    flex: 0 0 20%;
    min-width: 150px;
}

.logo {
    width: 7.3rem;
    height: 6.1rem;
}

.concept-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
}



.scroller {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    position: relative;
    mask-image: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller_items {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 768px) {

    .sub-title-text {
        font-size: 20px;
    }

    .logo {
        max-width: 70px;
    }

    .skill-item {
        padding: 0.5rem;
        width: 20%;
        flex: 0 0 20%;
    }


}

@media (max-width: 425px) {

    .logo {
        width: 4rem;
        height: 4rem;
    }


}