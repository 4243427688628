.header {
    display: flex;
    width: 100%;
    height: 8rem;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 2;
}

.header-in-experience {
    color: white !important;
}


.tags {
    display: flex;
    flex-direction: row;
    align-items: center;
}



.tag-button {
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0 1rem;
    transition: transform 0.3s ease;
}

.tag-button:hover .tag {
    transform: scale(1.1);
    background: rgba(255, 255, 255, 0.1);
}

.tag {
    color: #1B1C37;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;

    transition: background-color 0.3s ease;
}

.line {
    width: 5rem;
    height: .1rem;
    background-color: #1B1C37;
}


.white-line {
    width: 5rem;
    height: .1rem;
    background-color: white;
}

@media (max-width: 768px) {
    .header {
        min-width: 100%;
        height: auto;
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .tags {
        align-items: center;
        margin: 0;
        font-size: .01rem;
    }

    .tag-button {
        margin: 0.5rem 1rem;
    }

    .contact {
        margin: 1rem 0 0;
    }



}

@media (max-width: 425px) {
    .header {
        position: relative;
        top: 0;
        width: 100%;
        z-index: 1;
        background-color: #ffffff;
        padding: 1rem;
        box-sizing: border-box;
    }

    .tags {
        align-items: center;
        margin: 0;
    }

    .line {
        width: 1rem;
        height: .1rem;
        background-color: #1B1C37;
    }

    .tag {
        color: #1B1C37;
        font-family: Poppins;
        font-size: .8rem;
        font-weight: 500;
        transition: background-color 0.3s ease;
    }

    .tag-button {
        margin: 0 .5rem;
    }

}